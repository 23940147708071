import React from "react";
import { Routes, Route } from 'react-router-dom';
import GlobalStyles from 'styles/GlobalStyles';

import BagzzWorldLandingPage from "demos/BagzzWorldLandingPage";
import NewArrivals from "pages/NewArrivals";
import Women from "pages/Women";
import Men from "pages/Men";
import Kids from "pages/Kids";

export default function App() {

  return (
    <>
      <GlobalStyles />
      <Routes>
        <Route path="/" element={<BagzzWorldLandingPage />} />
        <Route path="/women" element={<Women />} />
        <Route path="/men" element={<Men />} />
        <Route path="/kids" element={<Kids />} />
        <Route path="/newarrivals" element={<NewArrivals />} />
      </Routes>
    </>
  )
}
