import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import TabGrid from "components/cards/TabCardGrid.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Header from "../components/headers/light.js";

const Men = () => {
    const HighlightedText = tw.span`bg-red-600 text-gray-100 px-4 transform -skew-x-12 inline-block`;

    return (

        <AnimationRevealPage>
            <Header />

            <TabGrid
                heading={
                    <>
                        Checkout our <HighlightedText> mens</HighlightedText> collection.
                    </>
                }
                usedBy="men"
                incomingClassification="Men"
            />
            
            <Footer />
        </AnimationRevealPage>
    )
}

export default Men;