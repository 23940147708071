import axios from "axios";

export const getProducts = async (category, classification, limit, offset) => {
    try {
        const res = await axios.get(`https://ms51r3761b.execute-api.ap-southeast-2.amazonaws.com/dev/products?category=${category}&classification=${classification}&limit=${limit}&offset=${offset}`);
        return res.data;
    } catch (err) {
        console.log('API fetch error', err);
    }

}